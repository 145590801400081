.input_autocomplete_block {
    position: relative;
    width: 100%;
}

ul.auto_complete_search_list {
    color: #e4e4e6;
    background-color: #21262d;
    /*border-color: #a1cbef;*/
    padding: 10px;
    margin: 0;
    list-style: none;
    position: absolute;
    width: 100%;
    z-index: 9999;
    max-height: 200px;
    overflow-y: scroll;
}


ul.auto_complete_search_list li {
    cursor: pointer;
    padding: 4px 0;
    line-height: 18px;
}
